<template>
  <div>
    <h4>รายการอุปกรณ์</h4>
    <b-row>
      <b-col lg="8">
        <cart-list></cart-list>
        <product-search @addToCart="check"></product-search>
        <date-selector @dateForm="getDate" v-if="carts.length"></date-selector>
      </b-col>
      <b-col lg="4" v-if="carts.length">
        <rental-info :info="main_order.info" />
        <b-card>
          <h4>ข้อมูลผู้เช่า</h4>
          <member-selector @member="MemberSelected"></member-selector>
        </b-card>
        <b-card>
          <h4>การชำระเงิน</h4>
          <div class="demo-inline-spacing mb-2">
            <b-form-radio-group
              v-model="main_order.info.require_advance_transfer"
            >
              <b-form-radio name="some-radio" :value="false">
                ชำระเต็มหน้าร้าน
              </b-form-radio>
              <b-form-radio name="some-radio" :value="true">
                โอนล่วงหน้า 30%
              </b-form-radio>
            </b-form-radio-group>
          </div>

          <div v-if="main_order.info.require_advance_transfer">
            <h5>ยอดที่ต้องโอนล่วงหน้า</h5>
            <div class="row">
              <div class="col-10">
                <b-form-input
                  v-model="main_order.info.advance_transfer_price"
                  class="mb-2"
                  autocomplete="off"
                />
              </div>
              <div class="col-2">
                <b-button @click="cal30()" variant="success"> 30% </b-button>
              </div>
            </div>
          </div>

          <h5>ยอดวางประกัน</h5>
          <b-form-input
            v-model="main_order.info.deposit_price"
            class="mb-2"
            autocomplete="off"
          />

          <h5>ค่าส่ง</h5>
          <b-form-input
            @change="check"
            v-model="main_order.info.shipping_price"
            class="mb-2"
            autocomplete="off"
          />

          <h5>ส่วนลด</h5>
          <b-form-input
            @change="check"
            v-model="main_order.info.discount_price"
            class="mb-2"
            autocomplete="off"
          />
          <div v-if="pred_deposit" class="d-flex justify-content-between">
            <h5>ยอดวางประกัน</h5>

            <div v-if="!loading.deposit">
              <h4 v-if="pred_deposit.complete_call === true">
                {{ pred_deposit.deposit_amount | number }}
              </h4>
              <h4 v-else><b-badge variant="warning">ขอยอดจาก พจ</b-badge></h4>
            </div>
            <div v-else>
              <b-spinner></b-spinner>
              <h4>Loading....</h4>
            </div>
          </div>

          <hr />

          <b-alert variant="danger" :show="!info.ready_to_book" class="mb-2">
            <div class="alert-body">
              <feather-icon icon="InfoIcon" class="mr-50" />
              มีอุปกรณ์บางรายการคิวไม่ว่าง !!!!
            </div>
          </b-alert>

          <div class="row mb-1">
            <div class="col">
              <b-button @click="checkDeposit()" variant="relief-success" block>
                <span v-if="loading.deposit === false"> เช็คยอดวาง Auto </span>
                <span v-else> <b-spinner small /> Loading </span>
              </b-button>
            </div>
            <!-- <div class="col">
              <b-button
                @click="createOrder('pending-deposit')"
                variant="relief-warning"
                block
              >
                ขอยอดวาง M
              </b-button>
            </div> -->
          </div>
          <div class="row">
            <div class="col">
              <b-button
                @click="createOrder('pending-info-no-lock')"
                variant="relief-warning"
                block
              >
                เปิดใบเสนอราคา
              </b-button>
            </div>
            <div class="col">
              <b-button
                @click="createOrder('complete')"
                variant="relief-success"
                block
              >
                ลงจองเลย</b-button
              >
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BAlert,
  BCard,
  BCardText,
  BCol,
  BRow,
  BButton,
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BBadge,
  BSpinner,
} from "bootstrap-vue";
import CartList from "./component/CartList.vue";
import DateSelector from "./component/DateSelector2.vue";
import ProductSearch from "./component/ProductSearch.vue";
import RentalInfo from "./component/RentalInfo.vue";
import MemberSelector from "./component/MemberSelector.vue";
import OrderAction from "./component/OrderAction.vue";
import _ from "lodash";

import moment from "moment";
import "moment-timezone";

export default {
  components: {
    BAlert,
    BCard,
    BCardText,
    BCol,
    BRow,
    BButton,
    CartList,
    DateSelector,
    ProductSearch,
    RentalInfo,
    MemberSelector,
    OrderAction,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BBadge,
    BSpinner,
  },
  computed: {
    info() {
      return this.$store.getters["adminOrder/getInfo"];
    },
    carts() {
      return this.$store.getters["adminOrder/getCarts"];
    },
  },
  data() {
    return {
      main_order: {
        info: {
          order_status: null,
          member_id: null,
          require_advance_transfer: false,
          advance_transfer_price: 0,
          deposit_price: 0,
          discount_price: 0,
          shipping_price: 0,
          pickup_method: "self",
          start_date: "",
          end_date: "",
          start_time: "",
          end_time: "",
        },
        carts: [],
      },
      loading: {
        deposit: false,
      },
      pred_deposit: null,
    };
  },
  methods: {
    /// เลือกสมาชิกแล้ว
    MemberSelected(value) {
      this.main_order.info.member_id = value._id;
    },
    /// เช็คคิว
    async check() {
      if (this.$store.getters["adminOrder/getCarts"].length) {
        this.main_order.carts = this.$store.getters["adminOrder/getCarts"];
      } else {
        this.main_order.carts = [];
      }
      if (this.main_order.carts.length > 0) {
        this.$http({
          method: "POST",
          url: "/queue/check-price",
          data: this.main_order,
        })
          .then((a) => {
            // this.main_order.carts = a.data.carts
            // this.main_order.info.ready_to_book = a.data.info.ready_to_book
            // this.main_order = a.data

            this.main_order.carts = a.data.data.carts;
            Object.assign(this.main_order.info, a.data.data.info);

            this.$store.dispatch("adminOrder/updateCheckPrice", a.data.data);
            this.$store.dispatch("adminOrder/updateInfo", a.data.data.info);
          })
          .catch((e) => {
            console.log("Error", e);
          });
      }
    },
    /// เลือกวันที่ วิธีรับคืน
    getDate(value) {
      Object.assign(this.main_order.info, value);
      this.check();
    },
    /// สร้าง Order
    async createOrder(order_status) {
      this.main_order.info.order_status = order_status;
      if (order_status === "pending-info-no-lock") {
        this.main_order.info.require_advance_transfer = true;
      }
      this.$http({
        method: "POST",
        url: "/order",
        data: this.main_order,
      })
        .then((x) => {
          this.$router.push({
            name: "order-view",
            params: { id: x.data.data._id },
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    cal30() {
      this.main_order.info.advance_transfer_price =
        Math.floor((this.main_order.info.total_net_price * 0.3) / 10) * 10;
    },
    async checkDeposit() {
      if (this.main_order.carts.length > 0) {
        this.loading.deposit = true;
        this.$http({
          method: "POST",
          url: "/order/check-deposit",
          data: this.main_order,
        })
          .then((a) => {
            this.pred_deposit = a.data.data;
            this.loading.deposit = false;
            // this.main_order.carts = a.data.data.carts;
            // Object.assign(this.main_order.info, a.data.data.info);
            // this.$store.dispatch("adminOrder/updateCheckPrice", a.data.data);
            // this.$store.dispatch("adminOrder/updateInfo", a.data.data.info);
          })
          .catch((e) => {
            this.loading.deposit = false;
          });
      }
    },
  },
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>

<style lang="scss" scoped>
pre {
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: 0.5rem;
}
</style>
