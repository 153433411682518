<template>
  <div>
    <b-card>
      <h4>รายละเอียดการเช่า</h4>
      <div class="row">
        <div class="col">จำนวนวัน</div>
        <div class="col text-right">{{ info.duration }} วัน</div>
      </div>
      <div class="row" v-if="info.pickup_method == 'messenger'">
        <div class="col">ค่าส่ง</div>
        <div class="col text-right">
          <div>{{ info.shipping_price | number }} บาท</div>
        </div>
      </div>
      <div class="row">
        <div class="col">ค่าเช่า</div>
        <div class="col text-right">
          {{ info.total_price_normal | number }} บาท
        </div>
      </div>
      <div class="row" v-if="info.discount_price > 0">
        <div class="col">ส่วนลด</div>
        <div class="col text-right">
          -{{ info.discount_price | number }} บาท
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col">
          <h5>รวมเป็นเงิน</h5>
        </div>
        <div class="col text-right">
          <h5>{{ info.total_net_price | number }} บาท</h5>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCol, BRow, BButton, BFormInput } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BFormInput,
  },
  data() {
    return {};
  },
  computed: {
    info() {
      return this.$store.getters["adminOrder/getInfo"];
    },
  },
  // props:['info'],
  created() {},
  filters: {
    number: function (x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
};
</script>
