<template>
  <div>
    <b-card title="">
      <!-- รายการในตะกร้า -->
      <div v-if="!carts.length">---- ไม่มีอุปกรณ์ในตะกร้า ----</div>
      <div v-if="carts.length">
        <div class="row" v-for="(item, index) in carts" :key="index">
          <div class="col">
            <b-img
              width="80px"
              :src="
                item.main_image
                  ? item.main_image
                  : 'https://st4.depositphotos.com/14953852/24787/v/600/depositphotos_247872612-stock-illustration-no-image-available-icon-vector.jpg'
              "
              size="lg"
              square
            ></b-img>
          </div>
          <div class="col">
            <h4>{{ item.web_name }}</h4>
            <h6 v-if="item.flat_rate">ครั้งละ {{ item.rental_price }} บาท</h6>
            <h6 v-else>วันละ {{ item.rental_price }} บาท</h6>
            <b-badge variant="success" v-if="item.avaliable"
              >อุปกรณ์ว่าง</b-badge
            >
            <b-badge variant="danger" v-if="!item.avaliable"
              >อุปกรณ์ไม่ว่าง</b-badge
            >
            <br />
            แบบ 1: {{ item.deposit_1 }} บาท
          </div>
          <div class="col text-right">
            <h3>{{ comma(item.rental_price_normal) }} ฿</h3>
            <b-button
              variant="relief-danger"
              class="mt-1"
              size="sm"
              @click="remove(item.cart_id)"
            >
              ลบ
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
          <div class="col-12">
            <hr />
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
  BButton,
  BAvatar,
  BBadge,
  BImg,
} from "bootstrap-vue";

export default {
  computed: {
    carts() {
      if (this.$store.getters["adminOrder/getCarts"].length) {
        return this.$store.getters["adminOrder/getCarts"];
      } else {
        return [];
      }
    },
  },
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BButton,
    BAvatar,
    BBadge,
    BImg,
  },
  data() {
    return {};
  },
  methods: {
    remove(cart_id) {
      this.$store.dispatch("adminOrder/deleteCart", cart_id);
      this.$parent.check();
    },
    comma(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
  },
  created() {},
};
</script>
