<template>
  <div>
    <b-card>
      <b-form>
        <!-- วิธีรับ -->
        <div class="row mb-1">
          <div class="col">
            <h4>การรับของ</h4>
            <!-- <a-radio-group size="large" v-model="pre.pickup" @change="CheckQueue" button-style="solid"> -->
            <b-form-radio-group
              @change="passEvent"
              v-model="form.pickup_method"
            >
              <b-form-radio value="self"> รับเองที่ร้าน </b-form-radio>
              <b-form-radio value="messenger"> ส่ง Messenger </b-form-radio>
              <b-form-radio value="shipping"> ส่งพัสดุ Kerry </b-form-radio>
            </b-form-radio-group>
          </div>
        </div>
        <div v-if="form.pickup_method == 'self'">
          <!-- วันที่ีรับ -->
          <b-form-group label="วันที่รับ">
            <date-picker
              v-model="form.start_date"
              :masks="{ input: 'D/M/YYYY' }"
              :model-config="modelConfig"
              mode="date"
              @input="passEvent"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <!-- :mode="date-time" -->
                <b-form-input
                  autocomplete="off"
                  class="border px-2 rounded"
                  :value="inputValue"
                  @click="togglePopover()"
                />
              </template>
            </date-picker>
          </b-form-group>
          <!-- เวลารับ -->
          <b-form-radio-group
            @change="passEvent"
            v-model="form.start_time"
            class="mb-2"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.start_time"
                name="some-radio"
                value="11:30:00"
              >
                หลัง 11:30
              </b-form-radio>
              <b-form-radio
                v-model="form.start_time"
                name="some-radio"
                value="14:00:00"
              >
                หลัง 14:00
              </b-form-radio>
              <b-form-radio
                v-model="form.start_time"
                name="some-radio"
                value="17:00:00"
              >
                หลัง 17:00
              </b-form-radio>
            </div>
          </b-form-radio-group>
          <!-- วันที่คืน -->
          <b-form-group label="วันที่คืน">
            <date-picker
              v-model="form.end_date"
              :masks="{ input: 'D/M/YYYY' }"
              :model-config="modelConfig"
              mode="date"
              @input="passEvent"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <b-form-input
                  autocomplete="off"
                  class="border px-2 rounded"
                  :value="inputValue"
                  @click="togglePopover()"
                />
              </template>
            </date-picker>
          </b-form-group>
          <!-- เวลาคืน -->
          <b-form-radio-group @change="passEvent" v-model="form.end_time">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.end_time"
                name="some-radio-2"
                value="13:00:00"
              >
                ก่อน 13:00
              </b-form-radio>
              <b-form-radio
                v-model="form.end_time"
                name="some-radio-2"
                value="14:00:00"
              >
                ก่อน 14:00
              </b-form-radio>
              <b-form-radio
                v-model="form.end_time"
                name="some-radio-2"
                value="16:00:00"
              >
                ก่อน 16:00
              </b-form-radio>
              <b-form-radio
                v-model="form.end_time"
                name="some-radio-2"
                value="19:00:00"
              >
                ก่อน 19:00
              </b-form-radio>
            </div>
          </b-form-radio-group>
        </div>
        <div v-else-if="form.pickup_method == 'messenger'">
          <!-- แมส วันที่ีรับ -->
          <b-form-group label="วันที่รับ">
            <date-picker
              v-model="form.start_date"
              :masks="{ input: 'D/M/YYYY' }"
              :model-config="modelConfig"
              mode="date"
              @input="passEvent"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <!-- :mode="date-time" -->
                <b-form-input
                  autocomplete="off"
                  class="border px-2 rounded"
                  :value="inputValue"
                  @click="togglePopover()"
                />
              </template>
            </date-picker>
          </b-form-group>
          <!-- แมส เวลารับ -->
          <b-form-radio-group
            @change="passEvent"
            v-model="form.start_time"
            class="mb-2"
          >
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.start_time"
                name="some-radio"
                value="11:30:00"
              >
                รอบที่ 1 13:00-16:00
              </b-form-radio>
              <b-form-radio
                v-model="form.start_time"
                name="some-radio"
                value="14:00:00"
              >
                รอบที่ 2 16:00-18:00
              </b-form-radio>
            </div>
          </b-form-radio-group>

          <!-- แมส วันที่คืน -->
          <b-form-group label="วันที่คืน">
            <date-picker
              v-model="form.end_date"
              :masks="{ input: 'D/M/YYYY' }"
              :model-config="modelConfig"
              mode="date"
              @input="passEvent"
            >
              <template v-slot="{ inputValue, togglePopover }">
                <b-form-input
                  autocomplete="off"
                  class="border px-2 rounded"
                  :value="inputValue"
                  @click="togglePopover()"
                />
              </template>
            </date-picker>
          </b-form-group>
          <!-- แมส เวลาคืน -->
          <b-form-radio-group @change="passEvent" v-model="form.end_time">
            <div class="demo-inline-spacing">
              <b-form-radio
                v-model="form.end_time"
                name="some-radio-2"
                value="13:00:00"
              >
                ก่อน 13:00
              </b-form-radio>
              <b-form-radio
                v-model="form.end_time"
                name="some-radio-2"
                value="16:00:00"
              >
                ก่อน 16:00
              </b-form-radio>
              <b-form-radio
                v-model="form.end_time"
                name="some-radio-2"
                value="19:00:00"
              >
                ก่อน 19:00
              </b-form-radio>
            </div>
          </b-form-radio-group>
        </div>
        <div v-else>
          <h3>ยังไม่ได้ทำ</h3>
        </div>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormRadioGroup,
  BForm,
  BFormInput,
  BFormRadio,
  BCard,
  BCardText,
  BCol,
  BRow,
  BButton,
  BAvatar,
  BBadge,
  BImg,
} from "bootstrap-vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import moment from "moment";
import "moment-timezone";

export default {
  // props: ['carts','form2'],
  // props:['date_time_info'],
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BButton,
    BAvatar,
    BBadge,
    BImg,
    DatePicker,
    BFormGroup,
    BFormInput,
    BFormRadio,
    BForm,
    BFormRadioGroup,
  },
  data() {
    return {
      // form:this.date_time_info,
      form: {
        pickup_method: "self",
        start_date: moment.tz().format("YYYY-MM-DD"),
        end_date: moment.tz().add(1, "day").format("YYYY-MM-DD"),
        start_time: "11:30:00",
        end_time: "19:00:00",
      },
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD", // Uses 'iso' if missing
      },
      pre: {},
    };
  },
  methods: {
    passEvent() {
      let temp = {};
      temp.start_date = moment.tz(
        this.form.start_date + " " + this.form.start_time,
        "Asia/Bangkok"
      );
      temp.end_date = moment.tz(
        this.form.end_date + " " + this.form.end_time,
        "Asia/Bangkok"
      );
      temp.pickup_method = this.form.pickup_method;

      // const diff =  temp.end_date.diff(temp.start_date)
      // const duration = moment.duration(diff);
      // const hour = duration.hours();
      // const day = duration.days();
      // const minute = duration.minutes();

      this.$emit("dateForm", temp);
      // {
      //     start_date:this.form.start_date,
      //     end_date:this.form.end_date,
      //     start_time:this.form.start_time,
      //     end_time:this.form.end_time,
      // }
    },
    CheckQueue() {},
  },
  created() {
    this.passEvent();
  },
  computed: {},
};
</script>
<style></style>
