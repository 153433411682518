<template>
    <div>
       
       
    </div>
</template>

<script>
import { BCard, BCardText, BCol, BRow, BButton,BFormRadioGroup,BFormRadio  } from 'bootstrap-vue'

export default {
    components: {
        BButton,
        BCard,
        BFormRadioGroup,
        BFormRadio 
    }
    
}
</script>