<template>
    <div>
        <b-card title="เพิ่มอุปกรณ์">
            <b-row>
                <b-col sm='10'>
                    <product-suggest @searchSelected="searchSelected" ref="form"/>
                </b-col>
                <b-col sm='2'>
                    <b-button block variant="relief-success" @click="addtocart">เพิ่ม</b-button>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import { BCard,BCol,BRow,BButton } from 'bootstrap-vue'
import ProductSuggest  from './ProductSuggest.vue'
export default {
    
    components:{
        BCard,
        BCol,
        BRow,
        BButton,
        ProductSuggest,
    },
    data(){
        return {
            carts:[],
            selected: null,
        }
    },
    methods:{
        searchSelected(el){
            this.selected = el
        },
        addtocart(){
            this.$store.dispatch('adminOrder/addToCart', this.selected)
            this.$emit('addToCart')
            this.$refs.form.clear()
        },
    }
}
</script>


